import React, { useRef } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import useStore from '../store/useStore'
import ContentHeader from '../components/Typography/ContentHeader'
import ContentText from '../components/Typography/ContentText'
import InnerLink from '../components/Typography/InnerLink'
import MediumImage from '../components/Image/MediumImage'
// import SectionStyle1 from '../components/curves/SectionStyle1'
import SEO from '../layouts/Seo'
import ImgWrapper from '../components/Image/ImgWrapper'
import ImgLightBox from '../components/lightbox/ImgLightBox'
import GeneralCarousel from '../contents/carousel/GeneralCarousel'
import KareGozluTablo from '../contents/tables/KareGozluTablo'
import DikdortgenGozluTablo from '../contents/tables/DikdortgenGozluTablo'
import OlcuAlert from '../contents/alert/OlcuAlert'
import HasirTelKullanim from '../contents/common/HasirtelKullanim'
const CitTelPage = () => {
  const { getImageByName } = useStore()
  const lightBoxRef = useRef()
  return (
    <>
      <SEO
        title='Çit Hasır Teli İmalatı - Erez Kaynak'
        description='Firmamız sıcak daldırma galvaniz , elektro galvaniz yada siyah ham çelik tel malzeme kullanarak punta kaynaklı çit hasır tel imalatı yapmaktadır. Firmamız çit hasır tellerini boyasız olarak imalatını yapmaktadır. Tel kalınlığı 2mm den 8mm tel kalınlığına kadar çit hasır tel imalatını yapabiliyoruz.'
        keywords='çit hasırı,çit teli,tel çit,bahçe çiti,Çit Hasır Teli İmalatı,çit hasır tel,yeşil bahçe çiti,batçe çit teli,panel çit,bahçe teli,tel çit fiyatları,bahçe çiti fiyatları,kafes teli,çit teli fiyatları,çit hasır tel fiyatları,çit hasır telleri kullanım alanları,çit hasarı istanbul,çit fens teli,fens teli istanbul'
        url='cit-hasir-teli-imalati'
        imgurl={getImageByName('25x100gozhasirtel').sm.src}
      />
      <ImgLightBox ref={lightBoxRef} gallery={[getImageByName('25x100gozhasirtel').original.src]} />
      <Box paddingX={3} paddingY={5}>
        <Grid container spacing={6} alignItems='flex-start'>
          <Grid item xs={12} sm={12} md={6} lg={7} xl={7}>
            <ContentHeader title='Çit Hasır Teli İmalatı' description='Türkiyenin her yerine gönderim yapılmaktadır.' />
            <ContentText>
              <p>
                Firmamız <strong>sıcak daldırma galvaniz, elektro galvaniz ya da siyah ham çelik tel</strong> malzeme kullanarak
                <strong>punta kaynaklı çit hasır tel</strong> imalatı yapmaktadır.
              </p>
              <p>Firmamız çit hasır tellerini boyasız olarak imalatını yapmaktadır.</p>
              <p>
                Tel kalınlığı 2mm den 8mm tel kalınlığına kadar <strong>çit hasır tel</strong> imalatını yapabiliyoruz.
              </p>
              Göz aralığı dikdörtgen de olmak üzere istenilen her ölçüde <strong>çit hasır tel</strong> yapılmaktadır.
              <p>
                Özel göz aralıklarında (örn; 35x75mm vb.) yeni kalıp yaparak hızlı bir şekilde müşterilerimizin ihtiyacı olan{' '}
                <strong>çit hasır tel</strong> uygun bir maliyet ile üretiyoruz.
              </p>
              <p>
                Çit hasır tellere Elektrostatik toz fırın boya işlemi yapılabilmektedir. Standart yada özel renk seçenekleri ile hasır
                teller elektrostatik toz boya ile boyanmaktadır. Firmamız bünyesinde boyasız olarak hasır tel imalatı gerçekleştirip,
                çalışmış olduğumuz elektrostatik toz boya yapan firmalar ile ihtiyacınız karşılanmaktadır.
              </p>
              <p>Detaylı bilgi yada fiyat teklifi almak için bizi arayınız.</p>
            </ContentText>
            <Box textAlign='center'>
              <InnerLink title='İletişim Bilgilerimiz İçin Tıklayınız' url='/iletisim' />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
            <ImgWrapper onClick={() => lightBoxRef.current.openLightBox()}>
              <MediumImage lazyHeight={500} img={getImageByName('25x100gozhasirtel')} alt='Özel hasır tel imalatı' />
            </ImgWrapper>
            <Box mt={2}>
              <MediumImage lazyHeight={500} img={getImageByName('celikhasirolculeri')} alt='özel göz hasır tel imalatı erez kaynak' />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <GeneralCarousel />

      <HasirTelKullanim title='Çit Hasır Tel Kullanım Alanları' />

      <Box p={3}>
        <OlcuAlert />
      </Box>
      <Box p={3} mb={5}>
        <KareGozluTablo />
      </Box>
      <Box p={3} mb={5}>
        <DikdortgenGozluTablo />
      </Box>
    </>
  )
}

export default CitTelPage
